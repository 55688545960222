import axios from "axios";
import connectionMixin from "@/mixins/connectionParams.js";
import runtimes from "@/assets/runtimes.json";

export default {
  name: "FunctionDetails",
  mixins: [connectionMixin],
  data() {
    return {
      successMessage: false,
      successMessageText: "",
      errorMessage: false,
      errorMessageText: "",
      logs: [],
      functionName: "",
      logLineNumber: 0,
      logLines: "",
      interval: "",
    };
  },
  mounted() {
    this.getFunctionDetails(this.$route.params.id, this.$route.params.language);
    this.getHostUrl(this.$route.params.id);
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
  },
  methods: {
    getFunctionDetails(id, language) {
          this.runtime_logo = language.toLowerCase();
          this.functionName = id;
    },

    getHostUrl(functionName) {
      axios
        .get(this.apihost + "/api/v1/services/" + functionName, this.opts)
        .then( response => {
          this.triggerExist = true;
          this.host = response.data.route;
        });
    },

    getTriggerDetails(name) {
      this.activationLogEnable = false;
      axios
        .get(this.apihost + "/triggers/" + name, this.opts)
        .then((response) => {
          this.triggerDetails = response.data;
          this.host = "http://" + this.triggerDetails.host;
        });
    },
    getDependency() {
      axios
        .get(this.apihost + this.downloadFileDependencyUrl, this.opts)
        .then((response) => {
          this.dependency = JSON.stringify(response.data);
          this.dependencyLang = this.downloadFileName.split(".")[1];
        })
        .catch((err) => {
          this.handlingError(err.response.status, this.functionName);
        });
    },
    downloadDependency() {
      var fileURL = window.URL.createObjectURL(new Blob([this.dependency]), {
        type: "text/plain",
      });
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", this.downloadFileName);
      fileLink.click();
    },

    //Open the delete function dialog
    deleteFunctionDialog() {
      this.deleteFunctionModalOpened = true;
    },
    //Delete Functions in Serverless environment
    deleteFunction(name) {
      axios.delete(this.apihost + "/api/v1/services/" + name, this.opts).then(() => {
        this.successMessage = true;
        this.successMessageText = this.$t(
          "message.functionlist.snackbar.function_deleted",
          { name: name }
        );
        this.deleteTriggerModalOpened = false;
        this.$router.push("/");
      });
    },

    getFunctionLogs(name) {  
        axios
          .get(this.apihost + "/api/v1/services/logs/" + name, this.opts)
          .then((response) => {
            if (response.status == 200) {
              this.logs = [];
              let data = response.data;
              for (let i = 0; i < data.length; i++) {
                this.logs.push({ id: i + 1, data: data[i] });
              }
            }
          });
    },

    toggleLogs() {
      this.hideLogs = !this.hideLogs;
      if (!this.hideLogs) {
        this.getFunctionLogs(this.$route.params.id);
      }
    },

    exportFunctionCode() {
      var fileURL = window.URL.createObjectURL(new Blob([this.code]), {
        type: "text/plain",
      });
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        this.functionName + runtimes[this.runtime_logo].extension
      );
      fileLink.click();
    },
  },
  beforeRouteLeave: function (to, from, next) {
    clearInterval(this.interval);
    next();
  },
};

<template>
  <v-container id="FunctionDetails">
    <core-toolbar-name
      :page-name="this.$t('message.pages.title.function_details')"
    ></core-toolbar-name>

    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet elevation="0">
          <v-row no-gutters>
            <!-- Runtime, Function name and trigger link -->
            <v-col sm="12">
              <v-card elevation="0">
                <div class="d-flex justify-start align-center">
                  <v-avatar class="mx-2" tile size="65">
                    <img
                      :src="
                        require('@/assets/lang-icons/' +
                          runtime_logo +
                          '_96.png')
                      "
                      alt="Runtime"
                      aspect-ratio="1"
                    />
                  </v-avatar>
                  <div>
                    <v-card-title class="text-h4 text-truncate pt-2">{{
                      functionName
                    }}</v-card-title>
                    <v-card-subtitle class="pb-2">
                      <span
                        v-if="triggerExist"
                        class="d-flex flex-no-wrap justify-start align-center"
                      >
                        <a :href="host" target="_blank" class="secondary--text">
                          <span id="triggerLink" class="text-truncate">{{
                            host
                          }}</span>
                        </a>
                      </span>
                      <span v-else class="font-weight-light">
                        {{
                          $t("message.labels.no-trigger-associated-to-function")
                        }}
                      </span>
                    </v-card-subtitle>
                  </div>
                  <div class="ml-auto">
                    <v-tooltip top v-if="!triggerExist">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          dark
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          @click="createTrigger(functionDetails.name)"
                        >
                          <v-icon>mdi-link-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("message.btns.click_to_expose") }}</span>
                    </v-tooltip>
                    <modal-delete-trigger
                      v-if="triggerExist"
                      :iconBtn="true"
                      :triggerName="triggerName"
                      :triggerExist.sync="triggerExist"
                    ></modal-delete-trigger>

                    <!-- Logs button-->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="toggleLogs">
                          <v-icon>mdi-text</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("message.labels.tooltip.log-show") }}</span>
                    </v-tooltip>
                    <!-- End Logs button-->

                    <!--Menu more-->
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item-group>
                          <v-list-item
                            @click="exportFunctionCode(functionDetails.name)"
                          >
                            <v-list-item-icon>
                              <v-icon color="primary">mdi-download</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                $t("message.labels.tooltip.export")
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click.stop="deleteFunctionDialog">
                            <v-list-item-icon>
                              <v-icon color="error">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                $t("message.btns.delete")
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <!--end menu more-->
                  </div>
                </div>
              </v-card>
            </v-col>
            <!-- end -->
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <code-explorer :availableFiles="availableFiles"></code-explorer>

    <!-- Logs -->
    <v-sheet :class="{ 'd-none': hideLogs, 'logs-style': true }">
      <v-row>
        <v-col cols="12" class="primary white--text text-uppercase">
          <h5 class="pt-2 px-2">Console</h5>
          <v-divider class="white"></v-divider>
        </v-col>
        <v-col cols="12" class="primary pt-0 white--text panel-max-height">
          <p v-for="log in logs" :key="log.id" class="logs-style">
            {{ log.data }}
          </p>
        </v-col>
      </v-row>
    </v-sheet>
    <!-- End Logs -->

    <!-- Communication message to the user -->
    <v-snackbar v-model="successMessage" top right color="success">
      <span class="text-uppercase">
        {{ successMessageText }}
      </span>
    </v-snackbar>
    <v-snackbar v-model="errorMessage" top right color="error">
      <span class="text-uppercase">
        {{ errorMessageText }}
      </span>
    </v-snackbar>
    <!-- end Communication message to the user -->

    <!-- Dialogs -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteFunctionModalOpened"
        persistent
        width="auto "
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title class="headline secondary white--text">
            {{ $t("message.functionlist.dialog.delete.delete") }}
            {{ $tc("message.labels.function", 1) }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="pt-4 body-1">
            {{ $t("message.functiondetails.dialog.delete.sure") }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="accent"
              text
              @click="deleteFunctionModalOpened = false"
            >
              {{ $t("message.btns.no") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteFunction(functionDetails.name)"
            >
              {{ $t("message.btns.yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end Dialogs -->
  </v-container>
</template>

<script>
import CoreToolbarName from "@/components/core/ToolbarName.vue";
import functionDetailsMixin from "@/mixins/views/pages/FunctionDetails.js";
import errorHandlingMixin from "@/mixins/components/core/ErrorHandling.js";
import ModalDeleteTrigger from "@/components/ModalDeleteTrigger.vue";
import CodeExplorer from '../CodeExplorer.vue';

export default {
  name: "FunctionDetails",

  components: {
    CoreToolbarName,
    ModalDeleteTrigger,
    CodeExplorer
  },
  data: () => ({
    dialog: false,
    runtime_logo: "code",
    deleteFunctionModalOpened: false,
    triggerName: "",
    triggerExist: false,
    functionDetails: "",
    host: "//",
    content: [],
    availableFiles: [],
    hideLogs: true
  }),
  computed: {},
  mixins: [functionDetailsMixin, errorHandlingMixin],
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.panel-max-height {
  min-height: 50px;
  max-height: 500px;
  overflow: auto;
}
#triggerLink {
  max-width: 600px;
  display: block;
}
.scroll {
  height: 12vh;
  overflow-y: scroll;
}
.logs-style {
  margin-bottom: 10px;
  font-size: 14px;
  font-family: monospace;
  padding: 0 8px 0 8px;
}
</style>
